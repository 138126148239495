import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RegularBreakpoints,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { SecurityType } from '../../../../../../graphql/__generated__/index';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetManagersData } from '../model/Shared.model';
import {
  cardContentStyle,
  cardHeaderStyle,
  cardHeight,
  cardTitleStyle,
  dividerStyle,
} from '../OfferingProfileCardItem';

export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
};
const tableContainerStyle: SxProps<Theme> = { maxHeight: 440 };
const tableRowStyle: SxProps<Theme> = {
  '& th': {
    color: 'text.secondary',
  },
};
const tableCellStyle: SxProps<Theme> = {
  whiteSpace: 'nowrap', // Prevents text from wrapping
};

const ManagersWidget: React.FC<Props> = ({ offeringProfile, cardSizes }) => {
  const managersData = useGetManagersData(offeringProfile);
  const sharesLabel = React.useMemo(() => {
    return offeringProfile.offeringById?.securityType === SecurityType.ConvertibleNote
      ? 'Notes Underwritten'
      : 'Shares Underwritten';
  }, [offeringProfile.offeringById?.securityType]);
  const title = 'Managers';
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight}>
        <Divider sx={dividerStyle} />
        <CardHeader
          title={
            <Typography variant="h3" sx={cardTitleStyle}>
              {title}
            </Typography>
          }
          aria-label={title}
          sx={cardHeaderStyle}
        />
        <CardContent sx={cardContentStyle}>
          <Grid container item alignItems="baseline">
            <Grid item xs={12}>
              <TableContainer sx={tableContainerStyle}>
                <Table stickyHeader>
                  <colgroup>
                    <col width="35%" />
                    <col width="20%" />
                    <col width="25%" />
                    <col width="20%" />
                  </colgroup>
                  <TableHead>
                    <TableRow sx={tableRowStyle}>
                      <TableCell>Underwriter</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell align="right" sx={tableCellStyle}>
                        {sharesLabel}
                      </TableCell>
                      <TableCell align="right">Economic %</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {managersData.map(
                      ({ name, role, underwritingBaseShares, pctUnderwritingBaseShares }) => {
                        return (
                          <TableRow key={name}>
                            <TableCell>{name}</TableCell>
                            <TableCell sx={tableCellStyle}>{role}</TableCell>
                            <TableCell align="right">{underwritingBaseShares}</TableCell>
                            <TableCell align="right">{pctUnderwritingBaseShares}</TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ManagersWidget;
